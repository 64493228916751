import styled from '@emotion/styled';
import { Body, Spinner } from '@sumup/circuit-ui';
import { Tooltip } from '@sumup/circuit-ui/legacy';
import { useRouter } from 'next/router';
import { useState, type FC } from 'react';

import type { UsePaymentPlanResult } from 'shared/hooks/orders/usePaymentPlan';
import type { IUiCartFields } from 'shared/infra/contentful/contentful';
import {
  formatFirstInstallmentAmount,
  formatFutureInstallmentAmount,
} from 'shared/services/PaymentPlanService';

import {
  DescriptionContainer,
  InfoIcon,
  OverviewWrapper,
  StyledHeadline,
  TooltipContainer,
} from '../../OrderOverview.styles';
import type { OverviewProps } from '../../OrderOverviewService';

type PaymentPlanContent = Pick<
  IUiCartFields,
  | 'freeText'
  | 'paymentScheduleTitleText'
  | 'paymentScheduleFirstInstallmentText'
  | 'paymentScheduleNextInstallmentsText'
  | 'paymentScheduleNextInstallmentsAdditionalInfoText'
  | 'paymentScheduleFeesText'
>;

const StyledSpinner = styled(Spinner)`
  margin: 30px auto;
`;

export interface Props extends OverviewProps {
  content: PaymentPlanContent;
  paymentPlanState: UsePaymentPlanResult;
  headerEnabled?: boolean;
  tooltipEnabled?: boolean;
}

const FreeText = styled(Body)`
  color: var(--cui-border-success);
  font-weight: var(--cui-font-weight-bold);
`;

export const PaymentPlanCard: FC<Props> = (props) => {
  const {
    content,
    paymentPlanState,
    noMargin,
    usedForCart,
    usedInModal,
    tooltipEnabled = true,
    headerEnabled = true,
  } = props;
  const { locale } = useRouter();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const toggleTooltip = (): void => {
    setTooltipVisible(!tooltipVisible);
  };

  if (paymentPlanState.state !== 'success') {
    return (
      <OverviewWrapper noMargin={noMargin} usedInModal={usedInModal}>
        <StyledSpinner />
      </OverviewWrapper>
    );
  }
  const paymentPlan = paymentPlanState.data;
  if (!paymentPlan) {
    return null;
  }

  const formattedFirstInstallmentAmount = formatFirstInstallmentAmount(
    paymentPlan,
    locale,
  );

  const formattedFutureInstallmentAmount = formatFutureInstallmentAmount(
    paymentPlan,
    locale,
  );

  return (
    <OverviewWrapper noMargin={noMargin} usedInModal={usedInModal}>
      {headerEnabled && (
        <StyledHeadline as="h2" size="three">
          {content.paymentScheduleTitleText}
        </StyledHeadline>
      )}
      <ul>
        <DescriptionContainer
          data-testid="first-installment"
          as="li"
          usedForCart={usedForCart}
        >
          <Body>{content.paymentScheduleFirstInstallmentText}</Body>
          <Body>{formattedFirstInstallmentAmount}</Body>
        </DescriptionContainer>
        <DescriptionContainer
          data-testid="future-installments"
          as="li"
          usedForCart={usedForCart}
        >
          <Body>
            {content.paymentScheduleNextInstallmentsText}
            {tooltipEnabled && (
              <>
                <InfoIcon
                  name="info"
                  aria-label="info"
                  size="16"
                  onMouseEnter={toggleTooltip}
                  onMouseLeave={toggleTooltip}
                />
                {tooltipVisible && (
                  <TooltipContainer>
                    <Tooltip align="top" position="right">
                      {
                        // eslint-disable-next-line max-len
                        content.paymentScheduleNextInstallmentsAdditionalInfoText
                      }
                    </Tooltip>
                  </TooltipContainer>
                )}
              </>
            )}
          </Body>
          <Body>{formattedFutureInstallmentAmount}</Body>
        </DescriptionContainer>
        <DescriptionContainer
          data-testid="fees"
          as="li"
          usedForCart={usedForCart}
        >
          <Body>{content.paymentScheduleFeesText}</Body>
          <FreeText>{content.freeText}</FreeText>
        </DescriptionContainer>
      </ul>
    </OverviewWrapper>
  );
};
